import {
    i18next, ApiError, ApiResponse, convertSnakeCaseKeysToCamelCase, Action, Dispatch,
} from '@manigo/manigo-commons';

import { HttpMethod } from 'models/api/http';

import { baseApiUrl } from 'config/config';
import { instanceOwnerClientId } from 'config/environment';

import { addRequestHeaders, cleanupCustomRequestHeaders, createApiError, headersToPlainObject, sanitizeQueryParams } from './http.helpers';
import { HttpRequestConfig } from './httpService.types';


class HttpServiceInstance {
    private readonly baseUrl;


    private readonly headers: Headers = new Headers({ 'Content-Type': 'application/json' });

    private userJwtToken;

    i18n?: typeof i18next;

    dispatch?: Dispatch;


    public constructor(baseUrl = baseApiUrl) {
        this.baseUrl = baseUrl;
        this.headers.set('client-id', instanceOwnerClientId);
        // TODO: PDK-4600 until it's resolved will be commented
        // this.headers.set('app-os', 'web'); // TODO: why 'app-os' ?
    }


    public configure(dispatch: Dispatch, i18n: typeof i18next) {
        this.dispatch = dispatch;
        this.i18n = i18n;
        return this;
    }

    private handleResponseError(error) {
        if (error.handled) {
            error.actions.forEach((action) => this.dispatch ? this.dispatch(action) : undefined);
        }

        return Promise.reject(error);
    }

    private buildUrl(endpointURL, queryParamsString) {
        return `${this.baseUrl}${endpointURL}${queryParamsString?.length > 0 ? `?${queryParamsString}` : ''}`;
    }

    private async request({
        method,
        url,
        data,
        config = {},
    }: {
        method: HttpMethod,
        url: string,
        config?: HttpRequestConfig,
        data?: any
    }): Promise<ApiResponse | ApiError> {
        const { download } = config;
        const queryParams = sanitizeQueryParams(config);

        addRequestHeaders(this.headers, config);

        const response = await fetch(
            this.buildUrl(url, queryParams),
            {
                method,
                mode: 'cors',
                headers: this.headers,
                body: JSON.stringify(data),
            },
        );

        cleanupCustomRequestHeaders(this.headers, config);

        const apiResponseObject = download && response.ok ? await response.blob() : await response.json();

        if (response.ok) {
            const parsedResponse = download ? apiResponseObject : convertSnakeCaseKeysToCamelCase(apiResponseObject);

            if (download) {
                return parsedResponse; // Directly return the Blob
            }

            return {
                ...parsedResponse,
                ...(config?.returnResponseHeaders
                    ? { headers: headersToPlainObject(response.headers) }
                    : {}),
            };
        } else {
            return this.handleResponseError(
                await createApiError({ response, config, apiResponseObject }, this.i18n),
            );
        }
    }


    // public methods
    public get(url: string, config?: HttpRequestConfig): Promise<any> {
        return this.request({ method: 'get', url, data: undefined, config });
    }

    public delete(url: string, data?: object, config?: HttpRequestConfig): Promise<any> {
        return this.request({ method: 'delete', url, data, config });
    }

    public put(url: string, data?: object, config?: HttpRequestConfig): Promise<any> {
        return this.request({ method: 'put', url, data, config });
    }

    public post(url: string, data?: object, config?: HttpRequestConfig): Promise<any> {
        return this.request({ method: 'post', url, data, config });
    }

    public patch(url: string, data?: object, config?: HttpRequestConfig): Promise<any> {
        return this.request({ method: 'PATCH', url, data, config });
    }

    public setUserToken(rawJwtToken?: string) {
        this.userJwtToken = rawJwtToken;
        if (rawJwtToken) {
            this.headers.set('Authorization', `Bearer ${rawJwtToken}`);
        }
    }

    public clearUserToken() {
        this.userJwtToken = undefined;
        this.headers.delete('Authorization');
    }

    public setSessionUuid(sessionUuid: string) {
        this.headers.set('session-uuid', sessionUuid);
    }

    public storeDispatch(action: Action) {
        return this.dispatch ? this.dispatch(action) : undefined;
    }
}


export const HttpService = new HttpServiceInstance();

export interface HttpServiceStore {
    setUserToken: HttpServiceInstance['setUserToken']
    clearUserToken: HttpServiceInstance['clearUserToken']
    setSessionUuid: HttpServiceInstance['setSessionUuid']
    storeDispatch: HttpServiceInstance['storeDispatch']
}

export const createHttpService = (): HttpServiceStore => ({
    setUserToken: HttpService.setUserToken.bind(HttpService),
    clearUserToken: HttpService.clearUserToken.bind(HttpService),
    setSessionUuid: HttpService.setSessionUuid.bind(HttpService),
    storeDispatch: HttpService.storeDispatch.bind(HttpService),
});
